<template>
    <div class="chat">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'15px'"/>
            </div>
            <div class="dp">
                <img :src="dp"/>
            </div>
            <div class="mid">
                <div class="name">{{name}}</div>
                <div class="status">Online</div>
            </div>
            <div class="icon left">
                <phone :size="'15px'"/>
            </div>
            <div class="icon">
                <optionicon :size="'15px'" />
            </div>
        </div>
        <div class="main" id="main_container">
            <!-- <div class="info">
                Messages in this chat are end to end stored <strong>Learn More</strong>
            </div> -->
            
            <chat v-for="(message, i) in messages" :chat="message" :key="message.id"
                :previous="i == 0 ? previous : messages[i - 1]"/>
            
            <div class="text" v-if="!chat.is_approved && !chat.declined">
                <b>{{ name }}</b> is yet to approve your chat request, you will be notififed once this chat request is 
                approved
            </div>
            
        </div>

        <send :can_load="true" @send="send($event)" :key="send_key"/>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')


import back from '../../icons/back.vue'
import optionicon from '../../icons/option.vue'
import phone from '../../icons/phone.vue'

import chat from '../../components/message/chat.vue'

import send from '../../components/message/send.vue'

export default {
    components: {
        back, optionicon, phone,
        chat,
        send
    },
    activated() {
        this.check_messages()
    },
    mounted() {
        this.chat = JSON.parse(localStorage.getItem('chat'))
        
        db.collection(this.chat.chat_id).orderBy('time', 'desc').limit(100).get().then(messages => {
            
            this.messages = messages.reverse()

            setTimeout(() => {
                this.scrollToBottom()
            }, 100);

            setTimeout(() => {
                this.check_messages()
            }, 1500);
        })


        db.collection('users').doc({'username': this.chat.user}).get()
        .then(user => {
            if (user == undefined) {
                let form = new FormData()
                form.append('username', this.chat.user)
                
                this.$http.post('/user/details', form)
                .then(data => {
                    let r = data.data
                    if (r.success) {
                        
                        this.dp = r.user.dp
                        this.name = r.user.firstname + ' ' + r.user.lastname
                        this.verified = r.user.verified
                        
                        db.collection('users').add(r.user)
                    }
                })
            } else {
                this.dp = user.dp
                this.name = user.firstname + ' ' + user.lastname
                this.verified = user.verified
            }
        })
    },
    computed: {
        new_message_received() {
            return this.$root.$data.new_message_received
        },
        chat_updated() {
            return this.$root.$data.chat_updated
        }
    },
    watch: {
        new_message_received(val) {
            if (this.chat.chat_id == val.chat_id) {
                this.messages.push(val)
                
                setTimeout(() => {
                    this.scrollToBottom()
                }, 100);
                
                if (this.$route.path == '/chat/' + this.chat.chat_id) {
                    
                    this.$socket.emit('message_seen', {
                        'message_id': val.message_id,
                        'chat_id': val.chat_id
                    })
                }
            }
        },
        chat_updated(val) {
            if (this.chat.chat_id == val.chat_id) {
                this.chat.is_approved = val.is_approved
                this.chat.declined = val.declined
            }
        }
    },
    data() {
        return {
            messages: [],
            previous: {
                sender: ''
            },
            chat: null,
            dp: '',
            name: '',
            verified: false,

            send_key: 1
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        scrollToBottom() {
            const container = this.$el.querySelector("#main_container");
            container.scrollTop = container.scrollHeight;

        },
        check_messages() {
            let me = this.$root.$data.username

            for (let x of this.messages) {
                if (x.delivered == false && x.sender != me) {
                    this.$socket.emit('message_delivered', {
                        'message_id': x.message_id,
                        'chat_id': x.chat_id
                    })
                }
                
                if (x.seen == false && x.sender != me) {
                    this.$socket.emit('message_seen', {
                        'message_id': x.message_id,
                        'chat_id': x.chat_id
                    })
                }
            }
        },
        send(e) {
            let form = new FormData()
            form.append('chat_id', this.chat.chat_id)
            form.append('text', e.text)
            form.append('value', e.value)
            form.append('type', e.type)

            this.$http.post('/chat/message', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    let message = {
                        'date': r.date,
                        'chat_id': this.chat.chat_id,
                        'count': r.count,
                        'type': e.type,
                        'value': r.value,
                        'text': e.text,
                        'sender': this.$root.$data.username,
                        'receiver': this.chat.user,
                        'message_id': r.message_id,
                        'sent': true,
                        'delivered': false,
                        'delivered_date': r.date,
                        'seen': false,
                        'seen_date': r.date,
                        'time': new Date(r.date).getTime(),
                        'notify': r.notify,
                        'meta': r.meta
                    }
                    
                    this.send_key++
                    this.messages.push(message)
                    
                    setTimeout(() => {
                        this.scrollToBottom()
                    }, 100);

                    this.$root.$data.new_message_sent = message

                    db.collection(this.chat.chat_id).add(message).then(() => {
                        db.collection('chats').doc({'chat_id': this.chat.chat_id}).update({
                            'updated_at': new Date(r.date).getTime(),
                            'count': r.count
                        })
                    })
                }
            })
        }
    },
    sockets: {
        new_message: function(data) {
            if (this.chat.chat_id == data.chat_id) {
                this.messages.push(data)
            }
        }
    }
}
</script>

<style scoped>
    .chat {
        display: flex;
        flex-direction: column;
        /* grid-template-rows: max-content auto max-content; */
        height: 100%;
        background-color: white;
    }
    .head {
        flex: none;
        display: grid;
        grid-template-columns: max-content max-content auto max-content max-content;
        align-items: center;
        width: 100vw;
        box-sizing: border-box;
        gap: 10px;
        padding: 12px 10px;
        /* padding-bottom: 35px; */
        /* color: white; */
        fill: #555;
        position: relative;
        background-color: white;
        z-index: 2;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        /* background-color: white; */
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .dp {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        /* margin-right: 10px; */

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 40px;
    }

    .mid {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .name {
        font-weight: 800;
        display: inline;
    }
    .status {
        font-size: 12px;
        /* font-weight: 500; */
        color: gray;
        margin-top: 3px;
    }
    .left {
        margin-left: auto;
    }

    .main {
        background-color: white;
        box-sizing: border-box;
        box-sizing: border-box;
        overflow: auto;
        padding: 15px;
        
        -webkit-overflow-scrolling: touch;
        flex: auto;
        
        /* display: flex;
        flex-direction: column-reverse; */
    }
    
    .main:after {
        /* content: '';
        display: table;
        clear: both;
        background: linear-gradient(var(--main) 0%, royalblue 100%);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        mix-blend-mode: screen;
        pointer-events: none; */
    }

    .text {
        text-align: center;
        margin-top: 20px;
        padding: 10px 0px;
        font-size: 12px;
        color: gray;
        border-top: 1px solid #ddd;
        line-height: 20px;
    }

    .info {
        color: #333;
        text-align: center;
        padding: 20px;
        padding-bottom: 0px;
        font-size: 14px;
        position: relative;
        z-index: 1;
    }
</style>